import React from "react";
import Header from "./Header";
import { useHistory } from "react-router-dom";

function PaymentStatus() {
  const parseQuery = (str, decode) => {
    const queries = {}
    if (!str) return queries
    const index = str.indexOf('?')
    let query = str.substring(index + 1)
    query = query.split('&')
    for (let j = 0; j < query.length; j++) {
      const [key, value] = query[j].split('=')
      if (decode) {
        try {
          queries[key] = atob(value)
        } catch (e) {
          queries[key] = value
        }
      } else if (key === 'stock') {
        queries.stock = decodeURIComponent(value).split(',')
        // if (queries.stock) {
        //   queries.stock.push(decodeURIComponent(value))
        // } else {
        //   queries.stock = [value]
        // }
      } else {
        queries[key] = decodeURIComponent(value)
      }
    }
    return queries
  }
  const history = useHistory()
  const {
    location: {
      search,
    },
  } = history
  console.log(search)
  const { orderId = '', status = '' } = parseQuery(search)
  console.log("orderId", orderId,"search", status)
  const isSuccess = status.toLowerCase() === 'txn_success' 
  return (
    <>
      <Header />
      <div className="paymentstatus container">
        {isSuccess ? (
        <h2>YOUR TRANSCATION WAS SUCCESSFUL</h2>
        ):(
          <h2>YOUR TRANSCATION WAS FAILED</h2>
        )}
      </div>
    </>
  );
}

export default PaymentStatus;
