import React from "react";
import Header from '../components/Header'
import Mainfooter from '../components/pagefooter'

import { Helmet } from 'react-helmet-async';
function HairFixing() {
  document.title = "Hair Fixing Clinic in Chennai | Relooking"
  const showinmoble={position:"absolute",
    bottom:"0",
    width:"100%"}
  return (
    
    <div>
        <Helmet>    
    <link rel="canonical" href="/ThankYoupage" />
</Helmet>
      <Header />
      <div style={{height:"100"}} class="content" >
       
        <h1 style={{fontSize:"50px", alignItems:"center"}}>
            Thank You!
        </h1>
        <p style={{ marginLeft:'2rem'}}>
        Thank you for submitting details.We will get back soon.
        <br/>
        <br/>
        
        </p>
      </div>
      <div class="content" ></div>
      <div class="content" ></div>
      <div style={showinmoble}>
      <  Mainfooter /></div>
    </div>
  );
}

export default HairFixing;