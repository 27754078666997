import React, { Suspense, useState, useEffect } from "react";
import {
  Route,
  Switch,
  useLocation,
  //useHistory
} from "react-router-dom";
//import {
// BrowserRouter,
//Route,
//Switch,
//useLocation,
//useHistory,
//} from "react-router-dom";
import "./App.css";
import "./css/style.css";
import { ROUTES } from "./routes";
import Modal from "react-modal";
import WhyChoose from "./components/WhyChose";
import { Images } from "./images/images";
import axios from 'axios';
import { firebase } from './firbas';
import { Helmet } from 'react-helmet-async';
Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
function App() {
  //const history = useHistory();
  const location = useLocation();

  const path = location.pathname;
  const showFloatingButton =
    path !== ROUTES.BOOK_APPOINTMENT.path &&
    path !== ROUTES.ABOUT.path &&
    path !== ROUTES.DISCLAIMER.path &&
    path !== ROUTES.TERMSANDCONDITIONS.path &&
    path !== ROUTES.RELOOKING.path;

  const [show, setShow] = useState(false);
  const [ip, setIP] = useState('');
  const [country_code, setcountrycode] = useState('');
  const [country_name, setcountry_name] = useState('');
  const [city, setcity] = useState('');
  const [Latitude, setlatitude] = useState('');
  const [longitude, setlongitude] = useState('');
  const [state, setstate] = useState('');
  const [ip2, setIP2] = useState('');
  const [network, setnetwork] = useState('');
  const [Provider, setprovider] = useState('');
  //const [fulldata, setfulldata] = useState(''); 
  //fd is our file descriptor
  const getData = async () => {
    const ipdata = await axios.get('https://geolocation-db.com/json/')
    //setfulldata(ipdata.data)
    setIP(ipdata.data.IPv4)
    setcountrycode(ipdata.data.country_code)
    setcountry_name(ipdata.data.country_name)
    setcity(ipdata.data.city)
    setlatitude(ipdata.data.latitude)
    setlongitude(ipdata.data.longitude)
    setstate(ipdata.data.state)
  }
  const getIp = async () => {
    const response = await fetch('https://ipapi.co/json/')
    const data = await response.json()
    // Set the IP address to the constant `ip`
    setIP2(data.ip)
    setprovider(data.org)
    setnetwork(data.network)
  }
  const [done, setDone] = useState(undefined);
  useEffect(() => {
    //passing getData method to the lifecycle method,e
    getData()
    getIp()
  }, [])
  const quoteData = {
    IpV4Address: ip,
    country_code: country_code,
    country_name: country_name,
    city: city,
    latitude: Latitude,
    longitude: longitude,
    state: state,
    VisterDate: new Date().toDateString('DD/MM/YYYY'),
    VisterTime: new Date().toTimeString(),
    VistorPage: window.location.href,
    SecIP: ip2,
    ORGProvider: Provider,
    network: network
  };
  const os = require('os');

  const networkInterfaces = os.networkInterfaces();
  // const ipp = networkInterfaces['eth0'][0]['address']

  console.log(networkInterfaces);
  //console.log(window.location.href)  
  const Month = new Date().getMonth()
  console.log(Month)
  if (network !== "" & state !== "") {
    firebase.database().ref('visitors_log_Apr-23').push(quoteData);
  }
  //gathering cookies  
  // 
  //var Dinst = 0;
  //firebase.database().ref('IpAddrescollection1').push(IpAddrescollection(path,Dinst));
  // const pa = location.pathname
    /* useEffect(() => {
    setTimeout(() => {
      setDone(true);

    }, 3000);
    {!done ? (
        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
          <img src={Images.Loadimage} />

        </div>
      ) : (
    
  }, []);*/
  return (
    <><Suspense fallback={() => <h1>LOADING</h1>}>
         <main>
          <Switch>

            <div className="App">
              {/* <Route exact path="/" component={HomePage} /> */}
              {Object.values(ROUTES).map((item) => {

                const { path, Component } = item;

                <Helmet>
                  <link rel="canonical" href={path} />
                </Helmet>
                // console.log(item);

                return (

                  <><Route key={path} exact path={path} component={Component} />

                  </>
                );

              }
              )}

            </div>
          </Switch>

          {showFloatingButton && (
            <button type="button" id="floating" onClick={() => setShow(true)}>
              Get A Quote
            </button >
          )}

          <Modal
            isOpen={show}
            onRequestClose={() => setShow(false)}
            style={customStyles}
            contentLabel="Book Appointment"

          >
            <WhyChoose />
          </Modal>

        </main>
    </Suspense></>
  );
}


export default App;

