import React, { useState, useEffect, useRef } from "react";
import "./ComponentCss/compCss.css";
import { Images } from "../images/images";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import { ROUTES } from "../routes";
import BookAppointment from "../views/BookAppointment";
import Modal from "react-modal";
import useWindowDimensions from "../components/getDimensions";
import {
  AiOutlineWhatsApp,
  AiOutlineInstagram,
  AiFillFacebook,
} from "react-icons/ai";
import { BsFacebook, BsTelephone } from "react-icons/bs";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const sticky = {
  position: "fixed",
  zIndex: "999",
  top: "0",
  width: "100%",
  transition: "all 0.5s ease",
  animation: "smoothScroll 1s forwards",
  opacity: "100%",
  backgroundcolor: "white",
}

function Header() {

  const [show, setShow] = useState(false);
  const isActive = (path) => {
    return window.location.pathname === path ? "nav-item active" : "nav-item";
  };
  const { width } = useWindowDimensions();
  const toggleModal = () => {
    setShow(!show);
  };

  const [cursor, setCursor] = useState('pointer');
  const changeCursor = () => {
    setCursor(prevState => {
      if (prevState === 'pointer') {
        return 'pointer';
      }
      return 'pointer';
    });
  }
  const showinmoble = { color: "#d41a7a", fontWeight: "800", item: "Left", borderBottom: "1px solid #e4e4e4", background: "Black" }
  const hideinmoble = { color: "#d41a7a", fontWeight: "800", textAlign: "Center", background: "Black", borderBottom: "1px solid #e4e4e4" }
  //style={sticky}
  return (
    <>
      <div  >
        <div >
          <nav class="navbar-expand-lg navbar-light nav-container" style={width > 600 ? showinmoble : hideinmoble}>
            <a class="navbar-brand" href="/">
              <img src={Images.IMG18} alt="Relookinglog" title="Relooking log " width="182" height="66" loading="eager" />

            </a>
            <a class="showOnMobile1" nav-item style={{ color: "white", fontWeight: "700", item: "Center" }}><br />
{             //<a href="https://www.google.com/maps/place/Relooking+-+An+Advanced+Cosmetic+Clinic+-+Best+Acne+treatment,+Chennai's+No.1+Hair+Loss+Treatment+,+Skin+Whitening,+Weight+Loss+Treatment+in+Chennai/@13.0310492,80.1632176,14z/data=!4m5!3m4!1s0x0:0x6b9305465db8b011!8m2!3d13.0355433!4d80.154845" style={{ color: "white" }}>Porur, </a>
}              <a href="https://www.google.com/maps/place/Relooking+-+An+Advanced+Cosmetic+Clinic,+R.A.Puram/@13.0297204,80.2552964,19.79z/data=!4m5!3m4!1s0x3a526784da8a186b:0xbc7d3bcdf46df6a3!8m2!3d13.0269448!4d80.262198" style={{ color: "white" }}>R A Puram  </a>
              & <a href="https://www.google.com/maps?q=12.8069431,80.2248838" style={{ color: "white" }}>Padur-OMR</a></a>

            <div class="showOnMobile align-items-center"  >
              <button
                style={{ marginRight: "1.5em", backgroundcolor: "#4CAF50", color: " #d41a7a", fontWeight: "700" }}
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
                Menu
                <span class="navbar-toggler-icon"></span>
              </button>


              <AiOutlineWhatsApp color="#d41a7a" style={{ marginRight: '1.5rem', cursor: "pointer" }} size="1.8em" onClick={() => window.open("https://wa.me/+919840313339/", '_blank')} />
              <BsFacebook color="#d41a7a" size="1.5em" style={{ margintop: '0.5rem', cursor: "pointer" }} onClick={() => window.open('http://fb.com/RelookingIndia', '_blank')} />
              <AiOutlineInstagram color="#d41a7a" style={{ marginLeft: '1.5rem', marginRight: '1.5rem', cursor: "pointer" }} size="2em" onClick={() => window.open('https://www.instagram.com/relooking_cosmetic_clinic/', '_blank')} />
              <BsTelephone color="#d41a7a" style={{ marginRight: '1.5rem', cursor: "pointer" }} size="1.6em" onClick={() => window.open('tel:+91-7667001111', '_blank')} />


              <a style={{ cursor: "pointer" }} href="tel:+91-7667001111">+91 76670 01111</a>

            </div>

          </nav>
        </div>
        <div >
          <Modal
            isOpen={show}
            onRequestClose={toggleModal}
            style={customStyles}
            contentLabel="Book Appointment"
          >
            <BookAppointment />
          </Modal>
          <nav class="navbar navbar-expand-lg navbar-light nav-container ">
            <a class="navbar-nav hideInMobile nav-item" style={{ flexDirection: "row" }}>
              <a href="https://wa.me/+919840313339/">
                <AiOutlineWhatsApp color="#d41a7a" style={{ marginRight: '1.5rem', cursor: "pointer" }} size="1.8em" onTouchStart={() => window.open("", '_blank')} /></a>
              <a href="http://fb.com/RelookingIndia">
                <AiFillFacebook color="#d41a7a" size="1.8em" style={{ marginRight: '1.5rem', cursor: "pointer" }} /></a>
              <a href="https://www.instagram.com/relooking_cosmetic_clinic/">
                <AiOutlineInstagram color="#d41a7a" style={{ marginRight: '1.5rem', cursor: "pointer" }} size="2em" />    </a>
              <a href="tel:+91-7667001111" >
                <BsTelephone color="#d41a7a" style={{ marginRight: '1.5rem', cursor: "pointer" }} size="1.6em" /></a>

            </a>
            <button
              style={{ marginLeft: "1.5em", color: " #d41a7a", fontWeight: "700" }}
              class="navbar-toggler hideInMobile nav-item"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"

            >Menu
              <span class="navbar-toggler-icon"></span>
            </button>

            {/*Inside the IconButton, we 
           can render various icons*/}

            <div class="collapse navbar-collapse " id="navbarSupportedContent" >
              <div class="collpad ">
                <ul class="navbar-nav">
                  <li class={`${isActive("/")}`}>
                    <a class="nav-link" href={ROUTES.RELOOKING.path}>
                      Home <span class="sr-only">(current)</span>
                    </a>
                  </li>
                  <Navbar id="hair" >
                    <Nav><NavDropdown
                      id="nav-dropdown-dark-example"
                      type="button" value="Change cursor"
                      onClick={changeCursor}
                      style={{ cursor: cursor }}

                      title="Treatment"

                    >
                      <Navbar id="hair" className="p-0">
                        <Nav>
                          <NavDropdown
                            id="nav-dropdown-dark-example"
                            title="Weight Loss"
                            class={`${isActive("/hair")}`}

                          >
                            {Object.values(ROUTES)
                              .filter((route) => route.service === "weight")
                              .map((route) => {
                                return (
                                  <NavDropdown.Item href={route.path}>
                                    {route.name}
                                  </NavDropdown.Item>
                                );
                              })}
                          </NavDropdown>
                        </Nav>
                      </Navbar>
                      <Navbar id="hair" className="p-0">
                        <Nav>
                          <NavDropdown
                            id="nav-dropdown-dark-example"
                            title="Skin"
                            class={`${isActive("/hair")}`}
                          >
                            {Object.values(ROUTES)
                              .filter((route) => route.service === "skin")
                              .map((route) => {
                                return (
                                  <NavDropdown.Item href={route.path}>
                                    {route.name}
                                  </NavDropdown.Item>
                                );
                              })}
                          </NavDropdown>
                        </Nav>
                      </Navbar>
                      <Navbar id="hair" className="p-0">
                        <Nav>
                          <NavDropdown
                            id="nav-dropdown-dark-example"
                            title="Hair"
                            class={`${isActive("/hair")}`}
                          >
                            {Object.values(ROUTES)
                              .filter((route) => route.service === "hair")
                              .map((route) => {
                                return (
                                  <NavDropdown.Item href={route.path}>
                                    {route.name}
                                  </NavDropdown.Item>
                                );
                              })}
                          </NavDropdown>
                        </Nav>
                      </Navbar>

                      <Navbar id="hair" className="p-0">
                        <Nav>
                          <NavDropdown
                            id="nav-dropdown-dark-example"
                            title="Cosmetic surgery"
                            class={`${isActive("/hair")}`}
                          >
                            {Object.values(ROUTES).filter(route => route.service === 'surgery').map(route => {
                              return (<NavDropdown.Item href={route.path}>
                                {route.name}
                              </NavDropdown.Item>)
                            })}
                          </NavDropdown>
                        </Nav>
                      </Navbar>
                      <Navbar id="hair" className="p-0">
                        <Nav>
                          <NavDropdown
                            id="nav-dropdown-dark-example"
                            title="Semi Permanent Make Up"
                            class={`${isActive("/hair")}`}
                          >
                            {Object.values(ROUTES).filter(route => route.service === 'spmu').map(route => {
                              return (<NavDropdown.Item href={route.path}>
                                {route.name}
                              </NavDropdown.Item>)
                            })}
                          </NavDropdown>
                        </Nav>
                      </Navbar>
                    </NavDropdown>
                    </Nav>
                  </Navbar>
                  <li class={`${isActive("/about")}`}>
                    <a class="nav-link" href={ROUTES.Blog.path}>
                      Blog
                    </a>
                  </li>
                  <li class={`${isActive("/about")}`}>
                    <a class="nav-link" href={ROUTES.PAYONLINE.path}>
                      Pay Online
                    </a>
                  </li>
                  <li class={`${isActive("/about")}`}>
                    <a class="nav-link" href={ROUTES.ABOUT.path}>
                      About
                    </a>
                  </li>
                  <li class={`${isActive("/Book")}`}>
                    <a class="nav-link" href={ROUTES.CONTACTUS.path}>
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <button
              class="btn-bookAppointment"
              type="button"
              onClick={toggleModal}
            >
              Book Appointment
            </button>


          </nav> </div></div>
    </>
  );
}

export default Header;
