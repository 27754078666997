import React from "react";
import Header from "../components/Header";
import Mainfooter from "../components/pagefooter";
import { Helmet } from 'react-helmet-async';

function TermsAndConditions() {
  document.title = "Terms And Conditions | Relooking"
  return (
    <div>
      <Header />
      <Helmet>    
    <link rel="canonical" href="/terms-and-conditions" />
    <meta
      name="description"
      content="Please read this document carefully before accessing or using Relooking Website or Related Services. To know more visit us now!"
      />
</Helmet>
      <div class="content" style={{background:"white"}}>
       <h1>Terms And Conditions</h1>        
        <p>
        &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;This website, www.relooking.co.in ("Website") is owned and managed by Velvom Healthcare Pvt Ltd ("Velvom"). 
        It is clarified that the term 'Relooking' shall include its affiliate and associate companies, directors, 
        officers, employees or cyber doctors. All references to the Website shall be deemed to mean a reference to 
        Relooking and vice versa, as the context may require. Except as stated herein, Relooking is the sole owner 
        of all information and content provided on the Website. Any person accessing the Website and/or availing of
         the services provided by Relooking through the Website shall be referred to as "the User".
         <br/> 

         &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;These terms and conditions govern your use of this website; by using this website, you accept these terms 
          and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, 
          you must not use this website.
        </p>
        <h2 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Information on the Website</h2>
        <p>
        &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;In spite of the Relooking's best efforts, the information on the Website may become outdated although, efforts will be 
        made to correct the errors and keep the content updated. Also, the information and materials on the Website are provided 
        on "as is" basis. Relooking will make best possible endeavours to provide satisfactory, accurate and useful information as 
        a response to any query. Relooking offers online resources designed to provide health, medical, and product-related information 
        and services such as chat with doctor, online discussion forums, communities, counselling services, phone consultations and
        e-mail communication etc. The content on the Website and the advice of the Relooking's cyber doctors is not intended to be, 
        nor is it a substitute for professional medical advice/attention, diagnosis or hands on treatment. In the event that the User
        finds an error or omission at this site it may inform the Website of the same.
         <br/> <br/>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;The content on the Website and the advice of Relooking's cyber doctors is not intended to be, nor is it a substitute for 
         professional medical advice/attention, diagnosis or hands on treatment. Further, the Website and its facilities are not 
         intended to replace a doctor's judgment or the care and treatment that only a qualified healthcare provider may give upon 
         due clinical examination of symptoms and/or co-relation of reports with such examination. The User shall provide full and
          complete information pertaining to the ailment (including but not limited to the User's medical history). The Relooking 
          shall be entitled to presume that the said information provided by you is true and correct.
Relooking does not (expressly or impliedly) guarantee the accuracy, integrity or quality of any information, response advice or med
icines prescribed/ sold to you. Relooking shall not be bound in any manner by any information contained on the Website.
</p>
<h3 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Usage of the Website</h3>
<p style={{}}>
&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;The User shall not under any circumstances whatsoever use the Website to send or post any messages or any material that is defamatory,
 unlawful, abusive, harassing, indecent, harmful, threatening, vulgar, obscene, sexually orientated, racially offensive, profane, 
 pornographic or violates any applicable law.You must not use this website in any way that causes, or may cause, damage to the website 
 or impairment of the availability or accessibility of the website; or in any way which is unlawful, illegal, fraudulent or harmful, or
  in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.<br/> <br/>

  &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;The User understands that the Website may not be accessible or access may be interrupted due to technical difficulty or otherwise. 
Relooking is not and shall not be responsible for any delay/ failure by the User to access the Website and/or any error/ omissions owing 
to such delay/ failure.<br/> <br/>

&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;The User warrants and represents to Relooking that the User is legally entitled to access the Website and avail of the services available. 
The User is not a minor and is competent to contract and/ or agree and understand the terms of these Terms of Service, Disclaimer and Privacy Policy.<br/> <br/>

&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;The User expressly agrees and permits Relooking to contact him/her via the contact number / e-mail addresses provided, from time to time for
 promotions, feedback, surveys that may be carried out by Relooking. Relooking may in its sole discretion be entitled to block/deny access to 
 any person under any circumstances whatsoever, without giving any prior notice.
</p><h3 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>You must not</h3>
<ul style={{fontSize:"20px"}}>
  <li >Sell, rent or sub-license material from the website;
  </li>
  <li>How any material from the website in public;</li>
  <li>Reproduce, duplicate, copy or otherwise exploit material on this website for a commercial purpose</li>
</ul>
<h2 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Please note</h2>
<ul style={{fontSize:"20px"}}>
<li>	The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
<li> 	This website uses cookies to monitor browsing preferences. If you do allow cookies to be used, the following personal information may be stored by us for use by third parties.</li>
<li> 	Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials 
    found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability 
   for any such inaccuracies or errors to the fullest extent permitted by law.</li>
   <li>	Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, 
    services or information available through this website meet your specific requirements.</li>
    <li>	This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
    </ul>
    <h2 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Payment Terms</h2>
    <p>
    &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;The User understands and agrees that the services furnished by Relooking shall commence on payment of fees for premium services. Relooking shall be entitled to terminate the services 
    furnished to the User in case of non-payment or delay or payment.</p>

    <h3 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Intellectual Property Rights</h3>

<p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;Intellectual Property Rights Relooking is solely entitled to the trade marks, names, logos and service marks, copyrights and all other intellectual property rights including but 
  not limited to the software, application, graphics, text and other materials used therein. Nothing contained on the Website should be construed as granting any licence or right to 
  use any trade mark without the prior written permission of Relooking. Any other material displayed on the Website that is capable of being protected as intellectual property shall 
  be the property of Relooking and nothing contained on the Website should be construed as granting any licence or right to use any such material without the prior written permission 
  of Relooking.</p>
  <h3 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Third party websites</h3>
  <p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;Please note that the Website may link you to third party websites maintained by others on the internet. These links are provided solely as a convenience to Users. References to 
    any entity, product, service, procedure, opinion or source of information should not be considered an endorsement, either direct or implied. In the event that you access any 
    third party website through the Website, Relooking highly recommends that you consult the respective privacy policy, disclaimer and terms of use/service of that website. The 
    links to third party websites are provided solely as a convenience to you and not as an endorsement by Relooking of the content on such third-party websites. Relookingcan not 
    take responsibility for information found on third party websites and if you decide to access linked third-party websites, you may do so at your own risk. Relooking does not and 
    can not control the contents and activities of other websites. No hypertext links shall be created from any website controlled by the User or otherwise to the Website without the 
    express prior written permission of Relooking. The User is to contact Relooking if it would like to link the Website or would like to request a link to its website.
    </p>
    <h3 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Disclaimer</h3>
    <p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;Relooking expressly disclaims any responsibility with respect to the completeness, accuracy and fitness of the responses given vide the Website 
      (through e-mail or otherwise). No warranty or representation of any kind, express or implied, is given by or on behalf of Relooking that the responses 
      to the queries, shall be appropriate, secure, reliable and free-from-error.

      <br/><br/>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;Relooking shall not be liable for any negligence (including gross negligence), loss, injury or damage (including without limitation direct, indirect, consequential, 
  special, exemplary or punitive loss, injury or damage) suffered by anyone on account of the use/performance of the information, response, advice furnished to you 
  (via e-mail or otherwise), any action or lack of action by the User and/ or medicines prescribed/ sold to the User. Relooking shall not be responsible in case of any 
  false/ misleading information/ statement made by the User during any sort of interaction with the Relooking or the Website.<br/><br/>
  &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
  Please note that health is the result of many factors, some of which are not yet fully understood and therefore it is important to rely only on the advice of a health 
  care professional to advice on any specific condition/situation. The results and duration of treatment may vary depending on the constitution of the human body. 
  The response to any query should not be relied upon as suggesting a course of treatment and it cannot replace/substitute regular medical check-ups in any manner whatsoever.
  </p>
  <h3 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Indemnity</h3>
<p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
The User indemnifies and shall hold safe harmless indemnified Relooking against any claim, demand, or damage, including reasonable attorneys' fees, asserted by any third 
party due to or arising out of your use of or conduct on the Website use of its services, in any manner whatsoever.
</p>
<h3 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Sharing of User information</h3>
<p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;The User hereby agrees and understands that Relooking shall be entitled to share the information of the User with its affiliates and associate companies, directors,
   officers, employees or cyber doctors, if and when required. The User does not and shall not have any objection with Relooking sharing such information. All information
    posted/ furnished by the Users on this Website shall solely belong to Relooking. For more details pertaining to rights, usage and sharing the User's information kindly 
    refer to the Privacy Policy.</p>

    <h3 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Entirety</h3>
<p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
These Terms of Service, Disclaimer and the Privacy Policy constitute the entire understanding and agreement between the User and Relooking. There are no understandings, 
representations or warranties (whether written or oral) except as expressly set forth or referred to herein.</p>
<h3 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Alteration</h3>
<p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
Relooking reserves the right at any time to change or discontinue without notice, any aspect or feature of the Website. The User expressly agrees and undertakes to be bound 
to the current version of the relevant terms and conditions (the "current version") as and when updated/altered. The User shall be solely responsible for reviewing these Terms
 of Service each time it visits the Website. Relooking shall not be bound to notify any change in these Terms of Service, Disclaimer and the Privacy Policy.
</p>


<h3 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Waiver</h3>
<p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
No delay, forbearance or indulgence in exercising or omission to exercise any right, power or remedy accruing to Relooking upon any default by the User shall impair any such right, 
power or remedy or shall be construed to be a waiver thereof or any acquiescence to such default, nor shall the action or inaction of the Investor in respect of any acquiescence by 
them in any default, affect or impair any right, power or remedy of the Investor in respect of any other default.
</p>
<h3 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Assignment</h3>
<p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
Relooking shall be entitled to assign all its rights and obligations arising out of or in relation to the Terms of Service, Disclaimer and Privacy Policy to any third party, as it may in its sole discretion decide.
</p>

<h3 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Severability</h3><p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
If one or more of the provisions of the Terms of Service, Disclaimer and the Privacy Policy hereof shall be void, invalid, illegal or unenforceable in any respect under any applicable 
law or decision, the validity, legality and enforceability of the remaining provisions herein contained shall not be affected or impaired in anyway.
</p>
<h3 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Arbitration</h3><p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
If any dispute, difference, claim or question shall at any time arise between Relooking and you, in relation to the use of the Website (in any manner whatsoever) such dispute, 
difference, claim or question shall be referred to arbitration. Relooking and you shall mutually appoint a sole arbitrator. Such arbitration shall be held in accordance with the
 provisions of the Arbitration and Conciliation Act, 1996 or any statutory modification or re-enactment thereof. The venue of arbitration shall be Hyderabad and the proceedings 
 shall be in the English language.
</p>
<h3 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Governing Law and Jurisdiction</h3><p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
These Terms of Service shall be governed by the laws of India and the courts in Hyderabad shall have exclusive jurisdiction.
</p>
<h3 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Acceptance by User</h3><p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
The User agrees and undertakes that the User has read and understood these Terms of Service, Disclaimer and the Privacy Policy. By using this website, 
you agree that the exclusions and limitations of liability set out in this Terms of Service, Disclaimer and the Privacy Policy are reasonable. If you do 
not think these are reasonable, you must not use this website. The User must first agree to these Terms of Service in order to access the Website and use its
 services. The User should not use the services available on the Website if you do not accept and agree to these Terms of Service.
 <br/><br/>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
 In the event that the User is not 18 years old or is incapable of entering into a contract under the Indian laws or other countries including the country 
 in which the User is resident or from which the User uses the services available on the Website. The User agrees that it is acting on its free will and consents 
 to the contents herein. Relooking strongly recommends that the User prints and saves a local copy of these Terms of Service, Disclaimer and the Privacy Policy 
 before proceeding to use the services of the Website.
</p>

      </div>

      <Mainfooter />
    </div>
  );
}

export default TermsAndConditions;
