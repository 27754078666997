import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
//import { getAnalytics } from "firebase/analytics";



// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDzkbO0eKdlQ3ctSMtcejMmQv496AvN0mU",
  authDomain: "relookingwebsitecooikes.firebaseapp.com",
  databaseURL: "https://relookingwebsitecooikes-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "relookingwebsitecooikes",
  storageBucket: "relookingwebsitecooikes.appspot.com",
  messagingSenderId: "831287002229",
  appId: "1:831287002229:web:98c9ecfa31e1651d63243c",
  measurementId: "G-Z1RBLD04WT"
};
  firebase.initializeApp(firebaseConfig);
  //firebase.getAnalytics(firebase.initializeApp(firebaseConfig));
  const auth = firebase.auth();
  const database = firebase.database();
  export{ firebase, auth, database}
