import axios from "axios";
import dayjs from "dayjs";
import { ROUTES } from "../routes";
import React, { useRef, useState } from "react";
import {
  // BrowserRouter,
  //Route,
  //Switch,
  //useLocation,
  useHistory,
} from "react-router-dom";
import emailjs from "@emailjs/browser";
import { Margin } from "@mui/icons-material";
import useWindowDimensions from "../components/getDimensions";
function BookAppointment() {
  const [Param, changeParam] = useState({
    clientInDate: "",
    clientId: "",
    serviceId1: [],
    comments: "",
  });
  const [resp, setResp] = useState(null);
  let history = useHistory();
  const onChange = (e) => {
    const { name, value } = e.target;
    changeParam({ ...Param, [name]: value });
  };

  const bookAppointment = (e) => {
    e.preventDefault();
    setResp(null);
    const bookedDate = dayjs(new Date()).format("DD/MM/YYYY");
    const bookingDate = dayjs(Param.clientInDate).format("DD/MM/YYYY HH:mm");
    const params = JSON.stringify({
      ...Param,
      clientInDate: bookingDate,
      waitCode: "S",
      waitTimeCode: "S",
      bookedDate,
      expectedStartTime: "1200",
      expectedEndTime: "1200",
      employeeId1: "0",
    });
    axios({
      method: "post",
      url: `https://app.salonspa.in/book/bridge.ashx?key=relookingcn&cmd=AWT&Param=${params}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Headers": "Special-Request-Header"
        // "Access-Control-Allow-Credentials": true,
        // "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT"
      },
    })
      .then((res) => {
        if (res.data.replyCode === 0) setResp("You booking was successful.");
        if (res.data.errorCode === "1003")
          setResp(
            "You are not registered with us. please contact support to resgister yourself."
          );
      })
      .catch((err) => console.log(err));
  };
  const form = useRef();

  const [invalidSubmit, toggleInvalid] = useState({ status: false, msg: "" });
  const sendEmail = (e) => {
    e.preventDefault();
    toggleInvalid({ status: false });
    const { name, number, service, branch } = form.current;
    if (!name.value || !number.value || !service.value || !branch.value) {
      toggleInvalid({ status: true, msg: "All fields are required" });
    } else if (number.value.length < 10) {
      toggleInvalid({ status: true, msg: "Enter a valid mobile number" });
    } else { // template_igcvacb // service_k24edss // AFHzVgBEPFXyPsXWt // old //service_77rv20a //template_f384ogf //user_6ti9WhqQH8T3pICsaGZfW
      setResp(null);
      console.log(name.value, number.value, service.value, branch.value)
      emailjs
        .sendForm(
          "service_k24edss",
          "template_igcvacb",
          form.current,
          "AFHzVgBEPFXyPsXWt"
        )
        .then(
          (result) => {
            console.log(result)
            if (result.status === 200) {
              window.alert(
                "Thank you for submitting details.We will get back soon."
              );
              window.location.assign("/ThankYoupage")
            }
          },
          (error) => {
            console.log(error.text)
            setResp(error.text);
          }
        );
    }
  }; // textAlign: "Center",
  const hideinmoble = { color: "#d41a7a", fontWeight: "800", paddingLeft: "6%", color: "Black", fontWeight: "700" }
  document.title = "Cosmetic Clinic in Chennai | Book Appointment | Relooking"
  return (
    <div class="row booking-content" style={hideinmoble}>
      <div class="col-12">
        <h1>Book Appointment</h1>
        <form ref={form} onSubmit={sendEmail}>
          <div class="form-group" >
            <label for="name">Name*</label>
            <input
              type="text"
              class="form-control"
              id="name"
              name="name"
              placeholder="Name"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group" >
            <label for="number">Phone Number*</label>
            <input
              type="text"
              class="form-control"
              id="number"
              name="number"
              placeholder="Whatsapp Number"
              maxLength={10}
            />
          </div>
          <div class="form-group" >
            <label for="exampleInputPassword1" >Select Branch</label>
            <select
              class="form-control"
              id="exampleInputPassword1"
              name="branch"
              placeholder="Brnach"
            >
              <option value="">Branch</option>
              { /*<option value="porur">Porur</option> */ }
              <option value="mandaveli">Mandaveli</option>
              <option value="Padur OMR">Padur OMR</option>
            </select>
          </div>
          <div class="form-group" >
            <label for="exampleInputPassword1">Select Service</label>
            <select
              class="form-control"
              placeholder="Services"
              aria-label="Services"
              name="service"
            >
              <option value="">Select Service</option>
              <option value="hair">Hair</option>
              <option value="skin">Skin</option>
              <option value="weight-loss">Weight Loss</option>
              <option value="surgery">Cosmetic Surgery</option>
            </select>
          </div>

          <div class="form-group" >
            <label for="exampleInputPassword1">Your Queries(Optional)</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputPassword1"
              name="query"
            />
          </div>
          <div>
            <button type="submit" class="btn Details-submit btn-primary">
              Submit
            </button>
            {invalidSubmit.status ? (
              <p style={{ color: "red" }}>{invalidSubmit.msg}</p>
            ) : null}
          </div>
        </form>

      </div>
    </div>
  );
}

export default BookAppointment;
