import React from "react";
import Header from "../components/Header";
import Mainfooter from "../components/pagefooter";
import { Helmet } from 'react-helmet-async';
function Refunds() {
  document.title = "Cosmetic Clinic in Chennai | Privacy Policy | Relooking"
  return (
    <div>
      <Helmet>    
      <link rel="canonical" href="\refunds" />
</Helmet>
     
      <Header />
      <div class="content" style={{background:"white"}}>
        <h1>Refunds</h1>

<p>The services available at Relooking branches are functional and try-before-you-buy. We provide free trial and let you make informed decision to fully evaluate our products before you make a purchase decision. Please use the trial session / services / consultations to make sure that the service meets your needs before buy our services.
<br/><br/>
If you purchase one of our services, after your payment has cleared your services will be delivered as per schedule / sessions. No refunds will be given after payment is done. During your consultation / trial, our support staff is available to assist answering to your questions. We strongly recommend that all customers clear their doubts about the services.
<br/><br/>
No refund will be made once a purchase is made. It is up the customer purchasing the service to evaluate a fit of the service during the trial period / consultation. Once purchase is made, the service is non-refundable but can be transferable to another person for any remaining amount of services.
</p>
<h2 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Acceptance of this Refund Policy</h2>
<p>
It is your responsibility to familiarize yourself with this refund policy. By placing an order for any of our services, you indicate that you have read this refund policy and that you agree with and fully accept the terms of this refund policy. If you do not agree with or fully accept the terms of this refund policy, we ask that you do not place an order with us.
<br/><br/>
Please contact customer service directly. Since relooking is offering non-tangible irrevocable goods we do not issue refunds once the order is accomplished and the product is sent. As a customer you are responsible for understanding this upon purchasing any item.

</p>

        </div>

        <Mainfooter />
      </div>
  )
}
export default Refunds;