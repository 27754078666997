import React, { useRef } from "react";
import Header from "../components/Header";
import Mainfooter from '../components/pagefooter'
import { Helmet } from 'react-helmet-async';
function PayOnline() {
  document.title = "Pay Online | Relooking"
  const form = useRef();
  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === "[object Date]";
  }

  function isObj(val) {
    return typeof val === "object";
  }

  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }
  const showinmoble={position:"absolute",
  bottom:"0",
  width:"100%"}
  function buildForm({ action, params }) {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", action);

    Object.keys(params).forEach((key) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", stringifyValue(params[key]));
      form.appendChild(input);
    });
    document.title = "Cosmetic Clinic in Chennai | Relooking"
    return form;
  }

  function post(details) {
    const form = buildForm(details);
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }

  const getData = (data) => {
    console.log(Object.values(data))
    const params = {amount: data.amount, email: data.email, phone: data.phone}
    console.log(params)
    return fetch("https://us-central1-stamin-98728.cloudfunctions.net/app/api/relookingpayment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Allow-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .catch((err) => console.log(err));
  };

  const makePayment = (e) => {
    e.preventDefault();
    const { amount, email, phone } = e.target;
    console.log(amount.value, email.value, phone.value)
    getData({ amount: amount.value, email: email.value, phone: phone.value }).then((response) => {      var information = {
        action: "https://securegw.paytm.in/order/process",
        params: response,
      };
      post(information);
    });
  };
  return (
    <>
     <Helmet>    
    <link rel="canonical" href="/pay-online" />
    <meta
      name="description"
      content="Make Online payments just by entering your email, phone number, and the amount, and book your appointment now with one of our experts"      />
</Helmet>
      <Header />
      <div className="payonline container">
        <h1>Pay Online</h1>
        <form ref={form} onSubmit={makePayment}>
          <div class="form-group">
            <label for="email">Email*</label>
            <input
              type="email"
              class="form-control"
              id="email"
              name="email"
              placeholder="Email"
            />
          </div>
          <div class="form-group">
            <label for="phone">Phone Number*</label>
            <input
              type="tel"
              class="form-control"
              id="phone"
              name="phone"
              placeholder="Phone Number"
              maxLength={10}
            />
          </div>
          <div class="form-group">
            <label for="amount">Amount*</label>
            <input
              type="number"
              class="form-control"
              id="amount"
              name="amount"
              placeholder="Amount"
            />
          </div>

          <div class="col">
            <button
              class="btn btn-primary btn-SpeakToUs my-2 my-sm-0 w-100"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
        
      </div>
    </>
  );
}

export default PayOnline;
