import { lazy } from "react";
import PaymentStatus from "./components/PaymentStatus";
import ContactUs from "./views/ContactUs";
import PayOnline from "./views/PayOnline";
import PrivacyPolicy from "./views/PrivacyPolicy";
import instalinkapp from "./views/instalinkapp";
import Refunds from "./views/refunds";
import TermsAndConditions from "./views/TermsAndConditions";
import WhyRelooking from "./views/WhyRelooking";
import ThankYoupage from "./views/ThankYoupage"
const AboutUs = lazy(() => import("./views/AboutUs"));
const Homeview = lazy(() => import("./views/Homeview"));
const Pagenotfound = lazy(() => import("./components/Branchfoter"));
const Blog = lazy(() => import("./views/Blog"));
const BookAppointment = lazy(() => import("./views/BookAppointment"));
const Disclaimer = lazy(() => import("./views/disclaimer"));
const AntiDandruffTreatment = lazy(() =>
  import("./views/Hair/AntiDandruffTrtmnt")
);
const HairFixing = lazy(() => import("./views/Hair/HairFixing"));
const HairRegrowth = lazy(() => import("./views/Hair/HairRegrowth"));

const HairStraightning = lazy(() => import("./views/Hair/HairStraightning"));
const HairTransplant = lazy(() => import("./views/Surgery/HairTransplant"));
const HomePage = lazy(() => import("./views/HomePage"));
const AcneAndPores = lazy(() => import("./views/Skin/AcneAndPores"));
const AdvancedFacials = lazy(() => import("./views/Skin/Advancedfacials"));
const DarkCircles = lazy(() => import("./views/Skin/DarkCircles"));
const DarkLips = lazy(() => import("./views/Skin/DarkLips"));
const LaserToning = lazy(() => import("./views/Skin/LaserToning"));

const PrivatePartWhitening = lazy(() =>
  import("./views/Skin/PrivatePartWhitening")
);
const SkinWhitening = lazy(() => import("./views/Skin/Skinwhitening"));
const TattooRemoval = lazy(() => import("./views/Skin/TattooRemoval"));
const WartMoleRemoval = lazy(() => import("./views/Skin/WartMoleRemoval"));
const LashLining = lazy(() => import("./views/Smpu/LashLining"));
const LipColoring = lazy(() => import("./views/Smpu/LipColoring"));
const MicroBlading = lazy(() => import("./views/Smpu/MicroBlading"));
const MoleCreation = lazy(() => import("./views/Smpu/MoleCreation"));
const BreastReductionAndEnhancement = lazy(() =>
  import("./views/Surgery/BreastReducAndEnch")
);
const FaceFatReduction = lazy(() => import("./views/Surgery/FaceFatReduc"));
const LinesAndWrinkles = lazy(() => import("./views/Surgery/LinesAndWrinkles"));
const RhinoPlasty = lazy(() => import("./views/Surgery/RhinoPlasty"));
const VaserLipoSuction = lazy(() => import("./views/Surgery/VaserLipoSuction"));
const CoolSculpting = lazy(() => import("./views/WeightLoss/CoolSculpting"));
const InchLoss = lazy(() => import("./views/WeightLoss/InchLoss"));
const LipoDetox = lazy(() => import("./views/WeightLoss/LipoDetox"));
const WeightLoss = lazy(() => import("./views/WeightLoss/WeightLoss"));
const Lippigmentation = lazy(() => import("./views/Pigmentation/Lippigmentation"));
//const CryogenicFreezing = lazy(() => import("./views/Surgery/CryogenicFreezing"));
const Acnenowbutnever = lazy(() => import("./views/Blogs/ACNENOWBUTNEVER"));
const BasicWeightLossExercises = lazy(() => import("./views/Blogs/BasicWeightLossExercises"));
const Hairfallcausesandtreatment = lazy(() => import("./views/Blogs/Hairfallcausesandtreatment"));
const Hairtransplant = lazy(() => import("./views/Blogs/Hairtransplant"));
const Howtogetsixpackabsfast = lazy(() => import("./views/Blogs/Howtogetsixpackabsfast"));
const Keratintreatmenforhair = lazy(() => import("./views/Blogs/Keratintreatmenforhair"));
const oilyskinmoisturizingboonorcurse = lazy(() => import("./views/Blogs/oilyskinmoisturizingboonorcurse"));
const UderstandingTrichology = lazy(() => import("./views/Blogs/UderstandingTrichology"));
const dryskinmoisturizing = lazy(() => import("./views/Blogs/dryskinmoisturizing"));
const secratetipforhairsecrate = lazy(() => import("./views/Blogs/Secratetipforhairsecrate"));
const Bestwayskinpigment = lazy(() => import("./views/Blogs/Bestwayskinpigment"));
const Chemicalpeel = lazy(() => import("./views/Blogs/Chemicalpeel"));
const Removesuntanfromskin = lazy(() => import("./views/Blogs/Removesuntanfromskin"));
const howtosleepafterhairtransplantprocedure = lazy(() => import("./views/Blogs/howtosleepafterhairtransplantprocedure"));
const Treatdifferenttypesofacne = lazy(() => import("./views/Blogs/Treatdifferenttypesofacne"));
const Isscalpmicropigmentationworthit = lazy(() => import("./views/Blogs/Isscalpmicropigmentationworthit"));
const prptherapy = lazy(() => import("./views/Blogs/prptherapy"));
const Prosofkoreanglasspeel = lazy(() => import("./views/Blogs/Prosofkoreanglasspeel"));
const Planfornosefillerseverythingaboutnonfillers = lazy(() => import("./views/Blogs/Planfornosefillerseverythingaboutnonfillers"));
const imgFolder =
  window.innerWidth < 450
    ? `${process.env.PUBLIC_URL}/images/categories_mobile`
    : `${process.env.PUBLIC_URL}/images/categories`;
export const ROUTES = {
  RELOOKING: { path: "/", Component: HomePage },
  ABOUT: { path: "/about-us", Component: AboutUs },
  Blog: { path: "/blogs", Component: Blog },
  Homeview: { path: "/home-view", Component: Homeview },
  BOOK_APPOINTMENT: { path: "/book-appointment", Component: BookAppointment },
  CONTACTUS: { path: "/contact-us", Component: ContactUs },
  PAYONLINE: { path: "/pay-online", Component: PayOnline },
  Instalink_app: { path: "/app-book", Component: instalinkapp },
  PAYMENTSTATUS: { path: "/billing", Component: PaymentStatus },
  DISCLAIMER: { path: "/disclaimer", Component: Disclaimer },
  WHYRELOOKING: { path: '/why-relooking', Component: WhyRelooking },
  Pagenotfound: { path: '/404', Component: Pagenotfound },
  TERMSANDCONDITIONS: {
    path: "/terms-and-conditions",
    Component: TermsAndConditions,
  },
  PRIVACYPOLICY: {
    path: "/privacy-policy",
    Component: PrivacyPolicy,
  },
  Refunds: {
    path: "/refunds",
    Component: Refunds,
  },
  ThankYoupage: {

    path: "/ThankYoupage",
    service: "ThankYoupage",
    Component: ThankYoupage,
  },
  Lippigmentation: {
    service: "Lippigmentation",
    blogtype: "skin",
    path: "/pigmentation",
    name: "Lip Pigmentation",
    Component: Lippigmentation,
    imgSrc: `${imgFolder}/Lip-pigmentation`,

  },

  Acnenowbutnever: {
    service: "Acnenowbutnever",
    blogtype: "skin",
    path: "/blogs/Acne-now-but-never",
    name: "Read More",
    Component: Acnenowbutnever,
    imgSrc: `${imgFolder}/BlogACNE.png`,

  },

  BasicWeightLossExercises: {
    service: "BasicWeightLossExercises",
    blogtype: "weight",
    path: "/blogs/basic-Weight-loss-exercises",
    name: "Read More",
    Component: BasicWeightLossExercises,
    imgSrc: `${imgFolder}/BlogWeightLoss.png`,

  },


  Chemicalpeel: {
    service: "Chemicalpeel",
    blogtype: "skin",
    path: "/blogs/chemical-peel",
    name: "Read More",
    Component: Chemicalpeel,
    imgSrc: `${imgFolder}/blogbanner10.png`,

  },
  Removesuntanfromskin: {
    service: "Removesuntanfromskin",
    blogtype: "skin",
    path: "/blogs/how-to-remove-sun-tan-from-skin",
    name: "Read More",
    Component: Removesuntanfromskin,
    imgSrc: `${imgFolder}/blogbanner11.png`,

  },
  howtosleepafterhairtransplantprocedure: {
    service: "howtosleepafterhairtransplantprocedure",
    blogtype: "hair",
    path: "/blogs/how-to-sleep-after-hair-transplant-procedure",
    name: "Read More",
    Component: howtosleepafterhairtransplantprocedure,
    imgSrc: `${imgFolder}/blogbanner12.png`,

  },
  Secratetipforhairsecrate: {
    service: "Secratetipforhairsecrate",
    blogtype: "hair",
    path: "/blogs/5-secret-tips-about-laser-hair-removal",
    name: "Read More",
    Component: secratetipforhairsecrate,
    imgSrc: `${imgFolder}/blogbanner13.png`,

  },
  Isscalpmicropigmentationworthit: {
    service: "Isscalpmicropigmentationworthit",
    blogtype: "hair",
    path: "/blogs/is-scalp-micropigmentation-worth-it",
    name: "Read More",
    Component: Isscalpmicropigmentationworthit,
    imgSrc: `${imgFolder}/blogbanner14.png`,

  },
  Bestwayskinpigment: {
    service: "Bestwayskinpigment",
    blogtype: "skin",
    path: "/blogs/best-way-skinpigment",
    name: "Read More",
    Component: Bestwayskinpigment,
    imgSrc: `${imgFolder}/blogbanner15.png`,

  },
  Treatdifferenttypesofacne: {
    service: "Treatdifferenttypesofacne",
    blogtype: "acne",
    path: "/blogs/how-to-treat-different-types-of-acne",
    name: "Read More",
    Component: Treatdifferenttypesofacne,
    imgSrc: `${imgFolder}/blogbanner16.png`,

  },
  prptherapy: {
    service: "prptherapy",
    blogtype: "hair",
    path: "/blogs/prp-therapy",
    name: "Read More",
    Component: prptherapy,
    imgSrc: `${imgFolder}/blogbanner17.png`,
  },

  Planfornosefillerseverythingaboutnonfillers: {
    service: "Planfornosefillerseverythingaboutnonfillers",
    blogtype: "skin",
    path: "/blogs/Plan-for-nose-filler-severy-thing-about-non-fillers",
    name: "Read More",
    Component: Planfornosefillerseverythingaboutnonfillers,
    imgSrc: `${imgFolder}/blogbanner18.png`,

  },
  Prosofkoreanglasspeel: {
    service: "Prosofkoreanglasspeel",
    blogtype: "skin",
    path: "/blogs/pros-of-korean-glasspeel",
    name: "Read More",
    Component: Prosofkoreanglasspeel,
    imgSrc: `${imgFolder}/blogbanner19.png`,

  },

  dryskinmoisturizing: {
    service: "dryskinmoisturizing",
    blogtype: "skin",
    path: "/blogs/dry-skin-moisturizing",
    name: "Read More",
    Component: dryskinmoisturizing,
    imgSrc: `${imgFolder}/BlogDRYSKINMOISTURIZING.png`,

  },
  Hairfallcausesandtreatment: {
    service: "Hairfallcausesandtreatment",
    blogtype: "hair",
    path: "/blogs/Hairfallcausesandtreatment",
    name: "Read More",
    Component: Hairfallcausesandtreatment,
    imgSrc: `${imgFolder}/BlogHAIRFALLCAUSESANDTREATMENT.png`,

  },

  Hairtransplant: {
    service: "Hairtransplant",
    blogtype: "skin",
    path: "/blogs/Hairtransplant",
    name: "Read More",
    Component: Hairtransplant,
    imgSrc: `${imgFolder}/BlogHAIRTRANSPLANT.png`,

  },
  Howtogetsixpackabsfast: {
    service: "Howtogetsixpackabsfast",
    blogtype: "weight",
    path: "/blogs/Howtogetsixpackabsfast",
    name: "Read More",
    Component: Howtogetsixpackabsfast,
    imgSrc: `${imgFolder}/BlogSIXPACKABSFAST.png`,

  },
  Keratintreatmenforhair: {
    service: "Keratintreatmenforhair",
    blogtype: "hair",
    path: "/blogs/Keratintreatmenforhair",
    name: "Read More",
    Component: Keratintreatmenforhair,
    imgSrc: `${imgFolder}/BlogKERATINTREATMENTFORHAIR.png`,

  },

  oilyskinmoisturizingboonorcurse: {
    service: "oilyskinmoisturizingboonorcurse",
    blogtype: "skin",
    path: "/blogs/oilyskinmoisturizingboonorcurse",
    name: "Read More",
    Component: oilyskinmoisturizingboonorcurse,
    imgSrc: `${imgFolder}/BlogOILYSKIN.png`,

  },

  UderstandingTrichology: {
    service: "UderstandingTrichology",
    blogtype: "skin",
    path: "/blogs/UderstandingTrichology",
    name: "Read More",
    Component: UderstandingTrichology,
    imgSrc: `${imgFolder}/weight-loss.jpg`,

  },


  WEIGHT_LOSS: {
    service: "weight",
    path: "/weight-loss",
    name: "Weight Loss",
    Component: WeightLoss,
    imgSrc: `${imgFolder}/weight-loss.jpg`,
  },
  INCH_LOSS: {
    service: "weight",
    path: "/inch-loss",
    name: "Inch Loss",
    imgSrc: `${imgFolder}/inch-loss.jpg`,
    Component: InchLoss,
  },
  COOL_SCULPTING: {
    service: "weight",
    path: "/cool-sculpting",
    name: "Cool Sculpting",
    imgSrc: `${imgFolder}/cool-sculpting.jpg`,
    Component: CoolSculpting,
  },
  LIPO_DETOX: {
    service: "weight",
    path: "/lipo-detox",
    name: "Lipo Detox",
    imgSrc: `${imgFolder}/lipo-detox.jpg`,
    Component: LipoDetox,
  },

  SKIN_WHITENING: {
    service: "skin",
    path: "/skin-whitening-glow",
    name: "Skin Whitening/Glow",
    imgSrc: `${imgFolder}/skin-whitening.jpg`,
    Component: SkinWhitening,
    portfolio: "skin",
  },
  Lippigmentation: {
    service: "skin",
    path: "/skin-pigmentation",
    name: "Skin Pigmentation",
    Component: Lippigmentation,
    imgSrc: `${imgFolder}/Lippigmentation`,
    portfolio: "skin"
  },
  ACNE_AND_PORES: {
    service: "skin",
    path: "/acne-and-pores",
    name: "Acne and Pores",
    imgSrc: `${imgFolder}/acne-and-pores.jpg`,
    Component: AcneAndPores,
    portfolio: "skins",
  },
  HAIR_REMOVAL_PRIVATE_PART_WHITENING: {
    service: "skins",
    path: "/hair-removal-private-part-whitening",
    name: "Hair Removal/Private Part Whitening",
    imgSrc: `${imgFolder}/hair-removal-private-part-whitening.jpg`,
    Component: PrivatePartWhitening,

  },
  DARK_CIRCLES: {
    service: "skin",
    path: "/dark-circles",
    name: "Dark Circles",
    imgSrc: `${imgFolder}/dark-circles.jpg`,
    Component: DarkCircles,
    portfolio: "skin",
  },
  DARK_LIPS: {
    service: "skin",
    path: "/dark-lips",
    name: "Dark Lips",
    imgSrc: `${imgFolder}/dark-lips.jpg`,
    Component: DarkLips, portfolio: "skin",
  },
  WART_AND_MOLE_REMOVAL: {
    service: "skin",
    path: "/wart-and-mole-removal",
    name: "Wart and Mole Removal",
    imgSrc: `${imgFolder}/wart-and-mole-removal.jpg`,
    Component: WartMoleRemoval,
  },
  LASER_TONING: {
    service: "skin",
    path: "/laser-toning",
    name: "Laser Toning",
    imgSrc: `${imgFolder}/laser-toning.jpg`,
    Component: LaserToning,
  },
  ADVANCED_FACIALS: {
    service: "skin",
    path: "/advanced-facials",
    name: "Advanced Facials",
    imgSrc: `${imgFolder}/advanced-facials.jpg`,
    Component: AdvancedFacials,
  },
  TATTOO_REMOVAL: {
    service: "skin",
    path: "/tattoo-removal",
    name: "Tattoo Removal",
    imgSrc: `${imgFolder}/tattoo-removal.jpg`,
    Component: TattooRemoval,
  },

  HAIR_STRAIGHTNING: {
    service: "hair",
    path: "/hair-strengthening",
    name: "Hair Strengthening",
    imgSrc: `${imgFolder}/hair-straightning.jpg`,
    Component: HairStraightning,
  },
  HAIR_REGROWTH: {
    service: "hair",
    path: "/hair-regrowth",
    name: "Hair Regrowth",
    imgSrc: `${imgFolder}/hair-regrowth.jpg`,
    Component: HairRegrowth,
  },
  COSMETIC_HAIR_FIXING: {
    service: "hair",
    path: "/cosmetic-hair-fixing",
    name: "Cosmetic Hair Fixing",
    imgSrc: `${imgFolder}/cosmetic-hair-fixing.jpg`,
    Component: HairFixing,
  },
  ANTI_DANDRUFF_TREATMENT: {
    service: "hair",
    path: "/anti-dandruff-treatment",
    name: "Anti Dandruff Treatment",
    imgSrc: `${imgFolder}/anti-dandruff-treatment.jpg`,
    Component: AntiDandruffTreatment,
  },
  VASER_LIPO_SUCTION: {
    service: "surgery",
    path: "/vaser-lipo-suction",
    name: "Liposuction",
    imgSrc: `${imgFolder}/vaser-lipo-suction.jpg`,
    Component: VaserLipoSuction,
    portfolio: "surgery",
  },
  RHINO_PLASTY: {
    service: "surgery",
    path: "/rhino-plasty",
    name: "Rhino Plasty",
    imgSrc: `${imgFolder}/rhino-plasty.jpg`,
    Component: RhinoPlasty,
    portfolio: "surgery",
  },
  HAIR_TRANSPLANT: {
    service: "surgery",
    path: "/hair-transplant",
    name: "Hair Transplant",
    imgSrc: `${imgFolder}/hair-transplant.jpg`,
    Component: HairTransplant,
    portfolio: "surgery",
  },
  BREAST_REDUCTION_AND_ENHANCEMENT: {
    service: "surgery",
    path: "/breast-reduction-and-enhancement",
    name: "Breast Augmentation",
    imgSrc: `${imgFolder}/breast-reduction-and-enhancement.jpg`,
    Component: BreastReductionAndEnhancement, portfolio: "surgery",
  },
  FACE_FAT_REDUCTION: {
    service: "surgery",
    path: "/face-fat-reduction",
    name: "Facelift",
    imgSrc: `${imgFolder}/face-fat-reduction.jpg`,
    Component: FaceFatReduction,
  },
  /*FACE_FAT_REUCTION: {
    service: "surgery",
    path: "/face-fat-reduction",
    name: "Gender Reassignment",
    imgSrc: `${imgFolder}/face-fat-reduction.jpg`,
    Component: FaceFatReduction,
  },*/

  LINES_AND_WRINKLES: {
    service: "surgery",
    path: "/lines-and-wrinkkles",
    name: "Lines and Wrinkles",
    imgSrc: `${imgFolder}/lines-and-wrinkles.jpg`,
    Component: LinesAndWrinkles,
  },
  /*CRYOGENIC_FREEZING: {
    service: "surgery",
    path: "/cryogenic-freezing",
    name: "Cryogenic Freezing",
    imgSrc: `${imgFolder}/cryogenic-freezing.jpg`,
    Component: CryogenicFreezing,
  },*/
  MICROBLADING: {
    service: "spmu",
    path: "/microblading",
    name: "Microblading",
    imgSrc: `${imgFolder}/microblading.jpg`,
    Component: MicroBlading,
  },
  LIP_COLORING: {
    service: "spmu",
    path: "/lip-coloring",
    name: "Lip Coloring",
    imgSrc: `${imgFolder}/lip-coloring.jpg`,
    Component: LipColoring,
  },
  LASH_LINING: {
    service: "spmu",
    path: "/lash-lining",
    name: "Lash Lining",
    imgSrc: `${imgFolder}/lash-lining.jpg`,
    Component: LashLining,
  },
  MOLE_CREATION: {
    service: "spmu",
    path: "/mole-creation",
    name: "Mole Creation",
    imgSrc: `${imgFolder}/mole-creation.jpg`,
    Component: MoleCreation,
  },

};


// /public/images/categories (WEB/DESKTOP)
// /public/images/categories_mobile (MOBILE)
// cryogenic freezing