import banner1 from './Homepage/banner1.png'
import banner2 from './Homepage/banner2.png'
import banner3 from './Homepage/banner5.png'
import banner4 from './Homepage/banner3.gif'
import banner5 from './Homepage/banner7.png'
import banner6 from './Homepage/banner4.png'
import banner7 from './Homepage/banner6.png'
import icon1 from './icon/icon1.png'
import icon2 from './icon/icon2.png'
import icon3 from './icon/icon3.png'
import icon4 from './icon/icon4.png'

import banner1m from './Homepage/banner1m.png'
import banner2m from './Homepage/banner7m.png'
import banner3m from './Homepage/banner4m.png'
import banner4m from './Homepage/banner3m.gif'
import banner5m from './Homepage/banner6m.png'
import banner6m from './Homepage/banner5m.png'
import banner7m from './Homepage/banner2m.png'

import homeview1 from './Home view/Homeview1.jpg'
import homeview2 from './Home view/Homeview2.jpg'
import homeview3 from './Home view/Homeview3.jpg'
import homeview4 from './Home view/Homeview4.jpg'
import homeview5 from './Home view/Homeview5.jpg'
import slide1 from './Home view/slide1.jpg'
import slide2 from './Home view/slide2.PNG'
import slide3 from './Home view/slide3.jpg'
import slide4 from './Home view/slide4.jpg'
import loadimage from './Home view/WhatsApp Video 2022-08-20 at 2.15.51 PM.gif'

import img5 from './Homepage/testimonial1.png'
import img6 from './Homepage/testimonial2.png'
import img7 from './Homepage/testimonial3.png'
import img8 from './Homepage/testimonial4.png'
import img9 from './Homepage/portfolio1.jpg'
import img10 from './Homepage/portfolio2.jpg'
import img11 from './Homepage/portfolio3.jpg'
import img12 from './Homepage/portfolio4.jpg'
import img13 from './Homepage/portfolio5.jpg'
import img15 from './Homepage/icon1.png'
import img16 from './Homepage/icon2.png'
import img15m from './Homepage/icons mobile (1).png'
import img16m from './Homepage/icons mobiles2.png'
import img17 from './Homepage/icon3.png'
import img18 from './Homepage/titleimg.png'
import img20 from './Services/contentimage1.png'
import img22 from './Services/contentimage2.png'
import img23 from './Services/background4.png'
import img24 from './Services/background5.png'

import img25 from './Homepage/icon4.png'
import img26 from './Homepage/icon5.png'
import img27 from './Homepage/icon6.png'
import img28 from './Homepage/icon7.png'

import googleimg from './Homepage/googleimg.svg'

import img29 from './Blog/image-gallery-brentwood-8.png'
import img30 from './Blog/Blogdryskin.png'
import img31 from './Blog/Bloghairloss.png'
import img32 from './Blog/Bloghairtransplant.png'
import img33 from './Blog/Blogkerataintreatment.png'
import img34 from './Blog/Blogoilskintreatment.png'
import img35 from './Blog/Blogsixpack.png'
import img36 from './Blog/blogweightloss.png'
import img37 from './Blog/Blogskincare.png'
import img38 from './Blog/bloguderstanding.png'
import img39 from './Services/skin pigmentation.png'

import img40 from './Homepage/ChemicalPeel.png'
import img41 from './Homepage/HairTransplant.png'
import img42 from './Homepage/Koreanpeel.png'
import img43 from './Homepage/Laser.png'
import img44 from './Homepage/Pigmentation.png'
import img45 from './Homepage/PRP.png'
import img46 from './Homepage/RHYNOPLASTY.png'
import img47 from './Homepage/ScalpMicropigmentation.png'
import img48 from './Homepage/Suntan.png'
import img49 from './Homepage/Typeofacne.png'


export const Images={
    IMG5:img5,
    IMG6:img6,
    IMG7:img7,
    IMG8:img8,
    IMG9:img9,
    IMG10:img10,
    IMG11:img11,
    IMG12:img12,
    IMG13:img13,
    IMG15:img15,
    IMG16:img16,
    IMG15m:img15m,
    IMG16m:img16m,
    IMG17:img17,
    IMG18:img18,
    IMG20:img20,
    IMG22:img22,
    IMG23:img23,
    IMG24:img24,
    IMG25:img25,
    IMG26:img26,
    IMG27:img27,
    IMG28:img28,
    Img29:img29,
    Img30:img30,
    Img31:img31,
    Img32:img32,
    Img33:img33,
    Img34:img34,
    Img35:img35,
    Img36:img36,
    Img37:img37,
    Img38:img38,
    Img39:img39,
    Img40:img40,
    Img41:img41,
    Img42:img42,
    Img43:img43,
    Img44:img44,
    Img45:img45,
    Img46:img46,
    Img47:img47,
    Img48:img48,
    Img49:img49,

    Icon1:icon1,
    Icon2:icon2,
    Icon3:icon3,
    Icon4:icon4,

    BANNER1: banner1,
    BANNER2: banner2,
    BANNER3: banner3,
    BANNER4: banner4,
    BANNER5: banner5,
    BANNER6: banner6,
    BANNER7: banner7,

    Homeview1: homeview1,
    Homeview2: homeview2,
    Homeview3: homeview3,
    Homeview4: homeview4,
    Homeview5: homeview5,
    Googleimg: googleimg,
    Slide1:slide1,
    Slide2:slide2,
    Slide3:slide3,
    Slide4:slide4,
    Loadimage:loadimage,

    BANNER1m: banner1m,
    BANNER2m: banner2m,
    BANNER3m: banner3m,
    BANNER4m: banner4m,
    BANNER5m: banner5m,
    BANNER6m: banner6m,
    BANNER7m: banner7m,
}