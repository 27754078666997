import React, { useRef, useState } from "react";
import "./ComponentCss/compCss.css";
import emailjs from "@emailjs/browser";
import { Margin } from "@mui/icons-material";
import useWindowDimensions from "../components/getDimensions";
import './whychose.css'
function WhyChoose() {
  const form = useRef();
  const [resp, setResp] = useState(null);
  const [invalidSubmit, toggleInvalid] = useState({ status: false, msg: "" });
  const { width } = useWindowDimensions();
  const showinmoble={color:"#d41a7a",fontWeight:"800",textAlign:"Left",paddingLeft:"8%",color:"White",fontWeight:"700"}
  const hideinmoble={color:"#d41a7a",fontWeight:"800",textAlign:"Center",paddingLeft:"6%",color:"White",fontWeight:"700"}
  const sendEmail = (e) => {
    e.preventDefault();
    toggleInvalid({ status: false });
    const { name, number, service, branch } = form.current;
    if (!name.value || !number.value || !service.value || !branch.value) {
      toggleInvalid({ status: true, msg: "All fields are required" });
    } else if (number.value.length < 10) {
      toggleInvalid({ status: true, msg: "Enter a valid mobile number" });
    } else {
      setResp(null);
      emailjs
        .sendForm(
          "service_k24edss",
          "template_igcvacb",
          form.current,
          "AFHzVgBEPFXyPsXWt"
        )
        .then(
          (result) => {
            if (result.status === 200)
              setResp(
                "Thank you for submitting details.We will get back soon."
              );
              window.location.assign("/ThankYoupage")
          },
          (error) => {
            setResp(error.text);
          }
        );
    }
  };

  return (
    <div class="row homeContact">
      <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 homeContactText">
        <h2 style={width>550?showinmoble:hideinmoble} >{resp ? resp : "Speak with us!"}</h2>
      </div>
      <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 homeContactSubmit">
        {!resp && (
          <form ref={form} onSubmit={sendEmail}>
            <div class="d-md-flex">
              <div class=" homeContactdata"style={{marginRight:"1.5rem"}}>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Name"
                  aria-label="Name"
                  name="name"
                  
                />
              </div>
              <div class=" homeContactdata" style={{marginRight:"1.5rem"}}>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Phone Number"
                  aria-label="Phone Number"
                  name="number"
                  maxLength={10}
                  
                />
              </div>
            
              <div class=" homeContactdata" style={{marginRight:"1.5rem"}}>
                <select
                  class="form-control"
                  placeholder="Branch"
                  aria-label="Branch"
                  name="branch"
                  
                >
                  <option value="">Branch</option>
                 {//  <option value="porur">Porur</option>
                 }
                  <option value="mandaveli">Mandaveli</option>
                  <option value="mandaveli">Padur OMR</option>
                </select>
                </div>
              <div class=" homeContactdata" >
                <select
                  class="form-control"
                  placeholder="Services"
                  aria-label="Services"
                  name="service"
                  
                >
                  <option value="">Service</option>
                  <option value="hair">Hair</option>
                  <option value="skin">Skin</option>
                  <option value="weight-loss">Weight Loss</option>
                  <option value="surgery">Cosmetic Surgery</option>
                </select>
                </div>
              <div class="homeContactdatabutton" style={{paddingLeft:"5rem"}}>
                <button
                  class="btn btn-SpeakToUs"
                  type="submit"                  
                >
                  Submit
                </button>
                {invalidSubmit.status ? (
                  <p style={{ color: "red" }}>{invalidSubmit.msg}</p>
                ) : null}
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
export default WhyChoose;
