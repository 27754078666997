import React from "react";
import Header from "../components/Header";
import Mainfooter from "../components/pagefooter";

function WhyRelooking() {
  return (
    <div>
      <Header />

      <div class="content">
        <h1>Why Relooking</h1>
        <p>
          WHY DO YOU NEED TO CHOOSE RELOOKING Please read the following terms
          and conditions carefully before registering, accessing, browsing,
          downloading or using the Site (defined below).
        </p>
      </div>

      <Mainfooter />
    </div>
  );
}

export default WhyRelooking;
