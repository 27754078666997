import React, { useRef, useState } from "react";
import Header from "../components/Header";
import Mainfooter from "../components/pagefooter";
import { Images } from "../images/images";
import emailjs from "@emailjs/browser";
import { BsWhatsapp } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";
//import{ROUTES} from "../routes"
import { Helmet } from 'react-helmet-async';
import {
  // BrowserRouter,
   //Route,
   //Switch,
   //useLocation,
   useHistory,
 } from "react-router-dom";
function ContactUs() {
  document.title = "Contact Us| Cosmetic Clinic in Chennai | Body Care Slimming Centre | Relooking"
let history=useHistory();
  const form = useRef();
  const [resp, setResp] = useState(null);
  const [invalidSubmit, toggleInvalid] = useState({ status: false, msg: "" });

  const sendEmail = (e) => {
    e.preventDefault();
    toggleInvalid({ status: false });
    const { name, number, service, branch } = form.current;
    if (!name.value || !number.value || !service.value || !branch.value) {
      toggleInvalid({ status: true, msg: "All fields are required" });
    } else if (number.value.length < 10) {
      toggleInvalid({ status: true, msg: "Enter a valid mobile number" });
    } else {
      setResp(null);
      emailjs
        .sendForm(
          "service_k24edss",
          "template_igcvacb",
          form.current,
          "AFHzVgBEPFXyPsXWt"
        )
        .then(
          (result) => {
            if (result.status === 200)
              setResp(
                "Thank you for submitting details.We will get back soon."
              ); 
              window.location.assign("/ThankYoupage")
              
          },
          (error) => {
            setResp(error.text);
          }
        );
    }
  };
  //#fbf7ef
  return (
    <div style={{backgroundColor:"whitesmoke"}}>
      <Helmet>    
      <meta
      name="description"
      content="Contact Relooking to get an appointment with our experts and doctors for advanced treatments. We offer complete skin care, body, and hair treatments at an effective cost"      />
    <link rel="canonical" href="/contact-us" />
</Helmet>
      <Header />
      <div class="HomePage-Image">
        <img src={Images.IMG24} alt=""/>
      </div>
      <div class="row booking-content">
        <div class="col-md-7 col-xs-12 booking-columns booking-left" style={{paddingTop: "3%",paddingBottom: "3%",paddingLeft:"5%"}}>
          <h2 style={{color:"#8d2069",fontWeight:"800"}}>Fill Your Details</h2>
          {resp && <h3>{resp}</h3>}
          <form ref={form} onSubmit={sendEmail}>
            <div class="form-group">
              <label for="name">Name*</label>
              <input
                type="text"
                class="form-control"
                id="name"
                name="name"
                placeholder="Name"
                aria-describedby="emailHelp"
              />
            </div>
            <div class="form-group">
              <label for="number">Phone Number*</label>
              <input
                type="text"
                class="form-control"
                id="number"
                name="number"
                placeholder="Whatsapp Number"
                maxLength={10}
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Select Branch</label>
              <select
                class="form-control"
                id="exampleInputPassword1"
                name="branch"
                placeholder="Brnach"
              >
                <option value="">Branch</option>
                { // <option value="porur">Porur</option>
                }
                <option value="mandaveli">Mandaveli</option>
                <option value="mandaveli">Padur OMR</option>
              </select>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Select Service</label>
              <select
                class="form-control"
                placeholder="Services"
                aria-label="Services"
                name="service"
              >
                <option value="">Select Service</option>
                <option value="hair">Hair</option>
                <option value="skin">Skin</option>
                <option value="weight-loss">Weight Loss</option>
                <option value="surgery">Cosmetic Surgery</option>
              </select>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Your Queries(Optional)</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputPassword1"
                name="query"
              />
            </div>
            <div>
              <button type="submit" class="btn Details-submit btn-primary">
                Submit
              </button>
              {invalidSubmit.status ? (
                <p style={{ color: "red" }}>{invalidSubmit.msg}</p>
              ) : null}
            </div>
          </form>
        </div>
        
        <div class="col-md-3 col-xs-12 booking-right hideinipod" style={{paddingTop: "3%",paddingBottom: "3%",paddingLeft:"5%"}}>
          <h1 style={{color:"#8d2069",fontWeight:"800"}}>Contact Us</h1>
          <p>
          {  /* <FaMapMarkerAlt /> <a class="pink-text" href="https://www.google.com/maps/place/Relooking+-+An+Advanced+Cosmetic+Clinic+-+Best+Acne+treatment,+Chennai's+No.1+Hair+Loss+Treatment+,+Skin+Whitening,+Weight+Loss+Treatment+in+Chennai/@13.0310492,80.1632176,14z/data=!4m5!3m4!1s0x0:0x6b9305465db8b011!8m2!3d13.0355433!4d80.154845">Porur</a>
            <br />
            43, Mount Poonamalle High Road,
            <br />
            Devi Nagar, Porur,
            <br />
            Chennai 600116
            <br />
            Tamil Nadu
            <br />
            INDIA
            <br />
              <br /> */}
            <FaMapMarkerAlt />
            <a class="pink-text" href="https://www.google.com/maps/place/Relooking+-+An+Advanced+Cosmetic+Clinic,+R.A.Puram/@13.0297204,80.2552964,19.79z/data=!4m5!3m4!1s0x3a526784da8a186b:0xbc7d3bcdf46df6a3!8m2!3d13.0269448!4d80.262198">Mandaveli</a>
            <br />
            Door No.60, Old No.36, Venkatakrishan Rd,
            <br />
            Chennai 600028
            <br />
            Tamil Nadu
            <br />
            INDIA
            <br />
            <br />
            <FaMapMarkerAlt />
            <a class="pink-text" href="https://www.google.com/maps?q=12.8069431,80.2248838">Padur OMR</a>
            <br />
            3rd Floor,
            <br/>
            No. 1 S.S Avenue 43,
            <br />
            Padur, OMR,
            <br/>
            Chennai 603103
            <br />
            Tamil Nadu
            <br />
            INDIA
            <br />
            <br/>
            <a href="tel:+91-7667001111" style={{color:"black"}}>Cell:+91 7667001111 </a>/<a href="tel:+91-9840313339" style={{color:"black"}} >+91 9840313339</a><br />
            <a href="https://wa.me/+919840313339"style={{color:"black",marginRight:'1.5rem'}}><BsWhatsapp color="green"size="1.5em"  /> +91 98403 1333</a> 
            <br />
            <br />
            <a href = "mailto: info@relooking.co.in"style={{color:"black"}}>Email: info@relooking.co.in</a>
            <br />
            <br />          
          </p>
        </div>
        <div class="col-md-2  col-xs-12 booking-right hideinipod " style={{paddingTop: "5%",paddingBottom: "5%",paddingRight: "5%",paddingLeft: "1%"}}>
        <br/>
{      //  <iframe title="Location"src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31096.58073646673!2d80.1632176!3d13.0310492!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6b9305465db8b011!2sRelooking%20-%20An%20Advanced%20Cosmetic%20Clinic%20-%20Best%20Acne%20treatment%2C%20Chennai&#39;s%20No.1%20Hair%20Loss%20Treatment%20%2C%20Skin%20Whitening%2C%20Weight%20Loss%20Treatment%20in%20Chennai!5e0!3m2!1sen!2sin!4v1650275276933!5m2!1sen!2sin" width="175" height="150" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
}      <br/> <br/>
      <br/>
      <br/> <br/>
      <iframe title="Location"src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d971.7733637378219!2d80.2552964!3d13.0297204!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526784da8a186b%3A0xbc7d3bcdf46df6a3!2sRelooking%20-%20An%20Advanced%20Cosmetic%20Clinic%2C%20R.A.Puram!5e0!3m2!1sen!2sin!4v1650276846628!5m2!1sen!2sin" width="175" height="150" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      <br/><br/>
      <br/>
      <iframe title="Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.5616148542645!2d80.22269511482028!3d12.806943090964111!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xae8faa4c890d62cd!2zMTLCsDQ4JzI1LjAiTiA4MMKwMTMnMjkuNiJF!5e0!3m2!1sen!2sin!4v1669986455193!5m2!1sen!2sin" width="175" height="150" style={{border:"0" }}allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <div className="showinipod">
        <div class="booking-right " style={{paddingTop: "3%",paddingBottom: "3%",paddingLeft:"8%"}}>
          <h1 style={{color:"#8d2069",fontWeight:"800"}}>Contact Us</h1>
          <div></div>
          <p>
            {/*<a class="pink-text"  href="https://www.google.com/maps/place/Relooking+-+An+Advanced+Cosmetic+Clinic+-+Best+Acne+treatment,+Chennai's+No.1+Hair+Loss+Treatment+,+Skin+Whitening,+Weight+Loss+Treatment+in+Chennai/@13.0310492,80.1632176,14z/data=!4m5!3m4!1s0x0:0x6b9305465db8b011!8m2!3d13.0355433!4d80.154845">Porur</a>
            <br />
            43, Mount Poonamalle High Road,
            <br />
            Devi Nagar, Porur,
            <br />
            Chennai 600116
            <br />
            Tamil Nadu
            <br />
            INDIA
            <br/>*/}
        <iframe title="Location" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31096.58073646673!2d80.1632176!3d13.0310492!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6b9305465db8b011!2sRelooking%20-%20An%20Advanced%20Cosmetic%20Clinic%20-%20Best%20Acne%20treatment%2C%20Chennai&#39;s%20No.1%20Hair%20Loss%20Treatment%20%2C%20Skin%20Whitening%2C%20Weight%20Loss%20Treatment%20in%20Chennai!5e0!3m2!1sen!2sin!4v1650275276933!5m2!1sen!2sin" width="200" height="150"style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    
            <br />
            <br />
            <FaMapMarkerAlt />
            <a class="pink-text" href="https://www.google.com/maps/place/Relooking+-+An+Advanced+Cosmetic+Clinic,+R.A.Puram/@13.0297204,80.2552964,19.79z/data=!4m5!3m4!1s0x3a526784da8a186b:0xbc7d3bcdf46df6a3!8m2!3d13.0269448!4d80.262198">Mandaveli</a>
            <br />
            Door No.60, Old No.36, Venkatakrishan Rd,
            <br />
            Chennai 600028
            <br />
            Tamil Nadu
            <br />
            INDIA
            <br />
            <iframe title="Location"src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d971.7733637378219!2d80.2552964!3d13.0297204!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526784da8a186b%3A0xbc7d3bcdf46df6a3!2sRelooking%20-%20An%20Advanced%20Cosmetic%20Clinic%2C%20R.A.Puram!5e0!3m2!1sen!2sin!4v1650276846628!5m2!1sen!2sin" width="175" height="150" style={{border:"0"}}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <br/> 
            <br />
            <FaMapMarkerAlt />
            <a class="pink-text" href="https://www.google.com/maps/place/12%C2%B048'25.0%22N+80%C2%B013'29.6%22E/@12.8069694,80.2228887,17.11z/data=!4m5!3m4!1s0x0:0xae8faa4c890d62cd!8m2!3d12.8069431!4d80.2248838"> Padur OMR</a>
            <br />
            3rd Floor, No 1 S.S Avenue 43,
            <br />
            Padur, OMR
           <br />
            Chennai 603103
            <br />
            Tamil Nadu
            <br />
            INDIA
            <br />
            <iframe title="Location"src="https://www.google.com/maps/place/12%C2%B048'25.0%22N+80%C2%B013'29.6%22E/@12.8069694,80.2228887,17.11z/data=!4m5!3m4!1s0x0:0xae8faa4c890d62cd!8m2!3d12.8069431!4d80.2248838" width="175" height="150" style={{border:"0"}}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

            <br/>
            <a href="tel:+91-7667001111" style={{color:"black"}}>Cell:+91 7667001111 </a>/<a href="tel:+91-9840313339" style={{color:"black"}} >+91 9840313339</a><br />
            <a href="https://wa.me/+919840313339"style={{color:"black",marginRight:'1.5rem'}}><BsWhatsapp color="green"size="1.5em"  /> +91 98403 13339</a> 
            <br />
            <br />
            <a href = "mailto: info@relooking.co.in"style={{color:"black"}}>Email: info@relooking.co.in</a>          
          </p>
        </div>
      
</div>

</div>
      <Mainfooter />
    </div>
  );
}

export default ContactUs;
