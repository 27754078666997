import React from "react";
import "./ComponentCss/compCss.css";
import {
  AiOutlineTwitter,
  AiOutlineYoutube,
  AiOutlineInstagram,
} from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import useWindowDimensions from "./getDimensions";

function Mainfooter() {
  const { width } = useWindowDimensions();
  return (
    <div >
      <div >
        <div class="d-lg-flex align-items-center customfooter " >
          <div className="justify-content-between align-items-center " style={width > 600 ? { display: "none" } : { display: "flex" }}>
            <a href="http://fb.com/RelookingIndia">
              <BsFacebook color="white" size="1.5em" style={{ margintop: '0.5rem', cursor: "pointer" }} /></a>
            <a href="https://www.instagram.com/relooking_cosmetic_clinic/">
              <AiOutlineInstagram color="white" style={{ marginLeft: '1.5rem', cursor: "pointer" }} size="2em" />  </a>
            <a href="https://www.youtube.com/channel/UC-TTiy4S3czz6JXlNjFXPLA?ab_channel=RelookingChennai">
              <AiOutlineYoutube color="white" style={{ marginLeft: '1.5rem', cursor: "pointer" }} size="2em" /> </a>
            <a href="https://twitter.com/RelookingIndia">
              <AiOutlineTwitter color="white" style={{ marginLeft: '1.5rem', cursor: "pointer" }} size="2em" /></a>

          </div>
          <p style={{ marginRight: '5rem' }}>&emsp;© Relooking Cosmetic Clinic. 2023. All Rights Reserved
            <br /> &emsp; Our Branches : &emsp;&emsp;
{            // <a href="https://www.google.com/maps/place/Relooking+-+An+Advanced+Cosmetic+Clinic+-+Best+Acne+treatment,+Chennai's+No.1+Hair+Loss+Treatment+,+Skin+Whitening,+Weight+Loss+Treatment+in+Chennai/@13.0310492,80.1632176,14z/data=!4m5!3m4!1s0x0:0x6b9305465db8b011!8m2!3d13.0355433!4d80.154845" style={{ color: "white" }}>Porur</a>  &emsp;&emsp; 
}<a href="https://www.google.com/maps/place/Relooking+-+An+Advanced+Cosmetic+Clinic,+R.A.Puram/@13.0297204,80.2552964,19.79z/data=!4m5!3m4!1s0x3a526784da8a186b:0xbc7d3bcdf46df6a3!8m2!3d13.0269448!4d80.262198" style={{ color: "white" }}>R A Puram </a>    &emsp;&emsp;    <a href="https://www.google.com/maps?q=12.8069431,80.2248838" style={{ color: "white" }}>Padur-OMR</a></p>

          <p style={{ marginRight: '10rem' }}><a href="/disclaimer" className="footerLinks" >Disclaimer</a> | <a href="/privacy-policy" className="footerLinks">Privacy Policy</a> | <a href="/terms-and-conditions" className="footerLinks">Terms & Conditions</a> | <a href="/refunds" className="footerLinks">Refunds</a></p>
          <div className="justify-content-between align-items-center" style={width > 600 ? { display: "flex" } : { display: "none" }}
          >
            <BsFacebook color="white" size="1.5em" style={{ margintop: '0.5rem', cursor: "pointer" }} onClick={() => window.open('http://fb.com/RelookingIndia', '_blank')} />
            <AiOutlineInstagram color="white" style={{ marginLeft: '1.5rem', cursor: "pointer" }} size="2em" onClick={() => window.open('https://www.instagram.com/relooking_cosmetic_clinic/', '_blank')} />
            <AiOutlineYoutube color="white" style={{ marginLeft: '1.5rem', cursor: "pointer" }} size="2em" onClick={() => window.open('https://www.youtube.com/channel/UC-TTiy4S3czz6JXlNjFXPLA?ab_channel=RelookingChennai', '_blank')} />
            <AiOutlineTwitter color="white" style={{ marginLeft: '1.5rem', cursor: "pointer" }} size="2em" onClick={() => window.open('https://twitter.com/RelookingIndia', '_blank')} />

          </div>
        </div>
      </div>
    </div>
  );
}
export default Mainfooter;
