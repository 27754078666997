import React from "react";
import Header from "../components/Header";
import Mainfooter from "../components/pagefooter";
import { Helmet } from 'react-helmet-async';
function PrivacyPolicy() {
  document.title = "Cosmetic Clinic in Chennai | Privacy Policy | Relooking"
  return (
    <div>
      <Helmet>    
      <link rel="canonical" href="\privacy-policy" />
      <meta
      name="description"
      content="Relooking is committed to protecting the privacy of our visitors. We use the information we collect on the site to improve or enhance your overall experience. 
"
      />
</Helmet>
     
      <Header />

      <div class="content" style={{background:"white"}}>
        <h1>Privacy Policy</h1>
       
        <p>
          Relooking is committed to protecting your privacy. We use the
          information we collect on the site to improve personalized features on
          our websites and to enhance your overall experience.
               Relooking utilizes the latest in Secure Server Technology to ensure
          that all personal information is protected to the highest standards.
        </p>
        <h2 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Data protection</h2>
        <p>
        Relooking never makes your personal details available to other
          companies for marketing purposes other than for the marketing of
          Relooking branded products where you have requested us to do so. We
          (and our representatives) may hold your personal details for research
          purposes. We do not sell, trade, or rent your personal information to
          others.
        </p>
        <h3 style={{color:"#8d2069",fontWeight:"800",textAlign:"left"}}>Your Consent</h3>
        <p>
          By using our web site, you consent to the collection and use of this
          information by Relooking. If we decide to change our privacy policy,
          we will post those changes on this page so that you are always aware
          of what information we collect, how we use it, and under what
          circumstances we disclose it.
      <br/>
        Relooking also provides links to affiliated sites. The privacy
          policies of these linked sites are the responsibility of the linked
          site and Relooking has no control or influence over their policies.
        </p>
      </div>

      <Mainfooter />
    </div>
  );
}

export default PrivacyPolicy;
