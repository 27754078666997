import React from "react";
import Mainfooter from "../components/pagefooter";
import { Images } from "../images/images";
import WhyChoose from "../components/WhyChose";
import useWindowDimensions from "../components/getDimensions";
import { Helmet } from 'react-helmet-async';
function Instalink() {
  const { width } = useWindowDimensions();

  const itemsToShow = width < 450 ? 1 : width < 780 ? 2 : width < 1030 ? 3 : 4;

  document.title = "Cosmetic Clinic in Chennai|Body Care Slimming Centre|Relooking"
  const showinmoble = {
    position: "absolute",
    bottom: "0",
    width: "100%"
  }
  console.log(width)
  const toggleModal = () => {
    window.location.href = "https://wa.me/+919840313339?text=I%20want%20book%20an%20Appointment"
  };
  return (

    <div>
      <Helmet>
        <link rel="canonical" href="/" />
      </Helmet>
      <meta name="description" content="Relooking is an advanced cosmetic clinic for men &amp; women. Relooking offers a wide range of cosmetic services such as weight loss, skincare, and hair care" />
      <div style={{ color: "#d41a7a", fontWeight: "800", textAlign: "Center", background: "Black", borderBottom: "1px solid #e4e4e4" }}>
        <a class="navbar-brand" href="/">
          <img src={Images.IMG18} alt="Relookinglog" title="Relooking log " width="182" height="66" loading="eager" />

        </a>
        <a class="showOnMobile1" nav-item style={{ color: "white", fontWeight: "700", item: "Center" }}><br />
{          // <a style={{ color: "white" }}>Porur, </a>
}          <a style={{ color: "white" }}>R A Puram  </a>
          & <a style={{ color: "white" }}>Padur-OMR</a></a>
      </div>

      <br />
      <br />
      <br />
      <div style={{ alignitems: "center", justifyContent: "center", display: "flex", fontSize: "25px" }}>
        <a>&ensp; &ensp;&ensp; &ensp;&ensp; &ensp;&ensp; &ensp;Relooking <br />
          &ensp; &ensp;Chennai Most Trusted Clinic
          <br />
          &ensp; &ensp; Safe | Affordable | Effective
        </a>
      </div>
      <div className="Appbuttonpagge">
        <button
          class="btn-bookAppointment"
          style={{ alignitems: "center", }}
          type="button"
          onClick={toggleModal}
        >
          Book Appointment

        </button>

      </div>
      <br />
      <br />
      <div style={{ alignitems: "center", justifyContent: "center", display: "flex" }}>
        <img src={Images.BANNER1m} />
      </div>
      <Mainfooter />
      <div style={showinmoble}>

      </div>
    </div>
  );
}

export default Instalink;
